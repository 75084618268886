import { analyticsComposable } from "@rds-vue-ui/analytics-gs-composable";
import { Result } from "maz-ui/components/MazPhoneNumberInput";
import { ref } from "vue";
import {
  Category,
  DegreeSeekingProgram,
  InterestArea,
} from "~/server/resolvers/resolvers-types";
import InterestAreaRfiService, {
  CategoryProgramsMap,
} from "~/src/services/InterestAreaRfiService";
import { RfiFormFieldShape, rfiZodSchema } from "~/src/services/rfiSchema";
import {
  FormattedProgram,
  RelationalFormattedProgram,
  WithRequired,
} from "~/types/types";
import { useRfiService } from "./useRfiService";

type DegreeTypeChangeArgs = {
  isStudyPage?: boolean;
  programList?: DegreeSeekingProgram[];
  isDegreePage?: boolean;
  // These props are used to set the program on pages that don't need a selection ( degree pages )
  // programs cms id
  programId?: string;
  programName?: string;
  career?: string;
};

// only used for setting the initial ref, not used for validation, use RfiFormFieldShape for validation
interface RfiFormFieldsRef {
  degreeType?: null | {
    text: string;
    value: string;
  };
  interestArea?: null | {
    text: string;
    value: string;
  };
  program: null | {
    text: string;
    value: string;
  };
  firstName?: string;
  lastName?: string;
  email?: string;
  phone: {
    number: string | null;
    formattedNumber: string | null;
    nationalNumber: string | null;
    isValid: boolean;
    countryCode: string | null;
  };
  isMilitary: boolean;
}

/**
 * @description Contains all logic for validating and submitting forms
 * @param options
 */
export default function (options: Partial<DegreeTypeChangeArgs> = {}) {
  interface RfiData {
    allCategory: Category[];
    allInterestAreaType: InterestArea[];
    allDegreeSeekingProgram: DegreeSeekingProgram[];
  }
  let data = ref<RfiData | undefined>();
  usePageQuery<RfiData>(
    `#graphql
        query{
          allCategory{
                title
          }
          allInterestAreaType(sort: {by:"title", order: "ASC"}){
            title
          }
          allDegreeSeekingProgram(sort: {by:"title", order: "ASC"}, 
          filter: {
            programFeature: {
              # Includes programs with programFeatures that have a title of RFI
              eq: "b4e8dd15-fed3-4594-a24b-ad8147dc9e26" 
            }
          }
          ){
            id
            code
            title
            category{
              title
            }
            interestAreas{
              title
            }
          }
      }`,
    {},
    { key: "rfi-data" }
  )
    .then((payload) => {
      data.value = payload;
    })
    .catch((error: Error) => {
      console.error(error);
    });

  const RfiService = useRfiService();

  // Setting up defaults, unused currently but makes futurue refactor easier
  const optionsWithDefaults = { ...options };

  const emailErrorMessage = ref("");
  const isEmailValid = ref<boolean | null>(null);
  const isPhoneValid = ref<boolean | null>(null);
  const showLoader = ref(false);
  const formDisabled = ref(false);

  const allCategories = ref<string[] | undefined>([]);
  const allInterestAreas = ref<string[] | undefined>([]);
  const allPrograms = ref<WithRequired<FormattedProgram, "interestAreas">[]>(
    []
  );
  const categoryProgramsMap = ref<CategoryProgramsMap>({});
  const relationalProgramData = ref<RelationalFormattedProgram>({});
  const degreeTypeOptions = ref<
    ReturnType<typeof RfiService.getDegreeTypeOptions>
  >([]);
  const interestAreaOptions = ref<
    ReturnType<typeof RfiService.getInterestAreaOptions>
  >([]);
  const programOptions = ref<ReturnType<typeof RfiService.getProgramOptions>>(
    []
  );

  const currentProgramCode = ref<string | null>(null);

  const currentProgramName = ref<string | null>(null);

  // initial form object, also stores final form data. Using zod for validation
  const form = ref<RfiFormFieldShape | RfiFormFieldsRef>({
    degreeType: null,
    interestArea: null,
    program: null,
    phone: {
      number: null,
      formattedNumber: null,
      nationalNumber: null,
      isValid: true,
      countryCode: null,
    },
    isMilitary: false,
  });

  /// Error messages Using zod safeparse
  const firstNameErrorMessage = computed(() => {
    if (form.value.hasOwnProperty("firstName")) {
      let result = rfiZodSchema.shape.firstName.safeParse(form.value.firstName);
      if (!result.success) {
        // Passes back the most recent error, potential to list all validation errors
        return result.error.errors[0].message;
      }
    }
    return "";
  });

  const lastNameErrorMessage = computed(() => {
    if (form.value.hasOwnProperty("lastName")) {
      let result = rfiZodSchema.shape.lastName.safeParse(form.value.lastName);
      if (!result.success) {
        // Passes back the most recent error, potential to list all validation errors.
        return result.error.errors[0].message;
      }
    }
    return "";
  });

  const validateEmail = () => {
    if (form.value.hasOwnProperty("email")) {
      const result = rfiZodSchema.shape.email.safeParse(form.value.email);
      if (!result.success) {
        emailErrorMessage.value = result.error.errors[0].message;
      } else {
        emailErrorMessage.value = ""; // Clear error if valid
      }
    }
  };

  const phoneErrorMessage = computed(() => {
    if (isPhoneValid.value === false) {
      return "Please enter a valid phone number and try again.";
    } else {
      return "";
    }
  });

  const formDataIsValid = computed(() => {
    let result = rfiZodSchema.safeParse(form.value);
    if (result.success) {
      return true;
    } else {
      return false;
    }
  });

  const programIsValid = computed(() => {
    let result = rfiZodSchema.shape.program.safeParse(form.value.program);
    if (!result.success) {
      return false;
    }
    return true;
  });

  const submitDisabled = computed(() => {
    if (
      isEmailValid.value === false ||
      isPhoneValid.value === false ||
      form.value.phone.isValid === false
    ) {
      return true;
    } else if (showLoader.value === true || formDisabled.value === true) {
      return true;
    } else if (formDataIsValid.value === false) {
      return true;
    } else {
      return false;
    }
  });

  const isUsOrCanada = computed(() => {
    // check if phone number is US or canada number
    return form.value.phone.countryCode &&
      (form.value.phone.countryCode == "CA" ||
        form.value.phone.countryCode == "US")
      ? true
      : false;
  });

  watch(
    () => form.value.email,
    (newEmail) => {
      // Trigger the email validation whenever email changes
      if (newEmail !== undefined) {
        validateEmail();
      }
    }
  );

  // watching form values to reset based on in the other options are possible
  watch(
    form,
    (newValue) => {
      // update/reset programs
      let programs: FormattedProgram[] = [];

      // If we don't need interest areas in the rfi we skip setting up all the IA's
      if (
        optionsWithDefaults.isStudyPage === true &&
        newValue.degreeType &&
        newValue.degreeType.value
      ) {
        programs = categoryProgramsMap.value[newValue.degreeType.value];

        // reset program only if it is not found programs
        if (newValue.program && newValue.program.value) {
          const searchProgram = programs.filter((item) => {
            return item.id === newValue.program!.value;
          });

          if (searchProgram.length === 0) {
            newValue.program = null;
          }
        }
      } else {
        // update/reset interestArea options, and populating programs
        let interestAreas: string[] = [];

        // if we have a degree type selected we use that, otherwise we display all IA's
        if (newValue.degreeType && newValue.degreeType.value) {
          interestAreas = RfiService.getDegreeTypeInterestAreas(
            allPrograms.value as Array<
              WithRequired<FormattedProgram, "interestAreas">
            >,
            newValue.degreeType!.value!
          );
        } else {
          interestAreas = allInterestAreas.value =
            RfiService.getAllFormattedInterestAreas(
              data?.value?.allInterestAreaType!
            );
        }

        // decide whether to reset selectedInterestArea  or not
        if (newValue.interestArea && newValue.interestArea.value) {
          const selectedInterestArea = interestAreas.filter((interestArea) => {
            return interestArea === newValue.interestArea!.value;
          });
          if (selectedInterestArea.length < 1) {
            newValue.interestArea = null;
          }
        }

        /**
         * Resetting the program
         * We prioritize filtering degree and interest area, then by interest area, then degree, then just display all
         */
        if (
          newValue.interestArea &&
          newValue.interestArea.value &&
          newValue.degreeType &&
          newValue.degreeType.value
        ) {
          // get program based on selected degreeType and interestArea
          programs =
            relationalProgramData.value[form.value.degreeType!.value!][
              form.value.interestArea!.value!
            ];
        } else if (newValue.interestArea && newValue.interestArea.value) {
          programs = RfiService.getInterestAreaPrograms(
            allPrograms.value as Array<
              WithRequired<FormattedProgram, "interestAreas">
            >,
            newValue!.interestArea!.value
          );
        } else if (newValue.degreeType && newValue.degreeType.value) {
          programs = RfiService.getDegreeTypePrograms(
            allPrograms.value as Array<
              WithRequired<FormattedProgram, "interestAreas">
            >,
            newValue.degreeType!.value!
          );
        } else {
          programs = allPrograms.value;
        }
        // decide whether to reset selectedProgram  or not
        if (newValue.program !== null && newValue.program.value) {
          const selectedProgram = programs.filter(
            (program: FormattedProgram) => {
              return program.id === newValue.program!.value;
            }
          );
          if (selectedProgram.length < 1) {
            newValue.program = null;
          }
        }

        // generate the interestArea options
        interestAreaOptions.value =
          RfiService.getInterestAreaOptions(interestAreas);
      }

      // generate program options
      programOptions.value = RfiService.getProgramOptions(programs);

      // Program code related data
      if (form.value.program && form.value.program.value) {
        currentProgramCode.value = RfiService.getProgramCode(
          allPrograms.value,
          form.value.program.value
        );
        currentProgramName.value = RfiService.getProgramNameFromCode(
          allPrograms.value,
          currentProgramCode.value!
        );
      } else {
        currentProgramCode.value = null;
        currentProgramName.value = null;
      }
    },
    {
      deep: true,
    }
  );

  // functions to handle user input on the degree, interest area and programs
  const handleDegreeTypeChange = () => {
    // degree type gtm event
    analyticsComposable.trackSelectEvent(
      "onclick",
      form.value.degreeType && form.value.degreeType.value
        ? "click"
        : "unclick",
      "select degree type",
      "main content",
      "request info",
      form.value.degreeType && form.value.degreeType.value
        ? form.value.degreeType.value!.toLowerCase()
        : "close cross"
    );
  };

  const handleInterestAreaChange = () => {
    // area of interest gtm event
    analyticsComposable.trackSelectEvent(
      "onclick",
      form.value.interestArea && form.value.interestArea.value
        ? "click"
        : "unclick",
      "select area of interest",
      "main content",
      "request info",
      form.value.interestArea && form.value.interestArea.value
        ? form.value.interestArea.value.toLowerCase()
        : "close cross"
    );
  };

  const handleProgramChange = () => {
    // program gtm event
    analyticsComposable.trackSelectEvent(
      "onclick",
      currentProgramName.value ? "click" : "unclick",
      "select desired program",
      "main content",
      "request info",
      currentProgramName.value
        ? currentProgramName.value!.toLowerCase()
        : "close cross"
    );
  };

  const handleFirstNameBlur = () => {
    const text = form.value.firstName ? form.value.firstName.toLowerCase() : "";
    analyticsComposable.trackFormEvent(
      "onclick",
      "click",
      "blur",
      "main content",
      "request info ^ first name",
      text
    );
  };

  const handleLastNameBlur = () => {
    const text = form.value.lastName ? form.value.lastName.toLowerCase() : "";
    analyticsComposable.trackFormEvent(
      "onclick",
      "click",
      "blur",
      "main content",
      "request info ^ last name",
      text
    );
  };

  const handleEmailBlur = () => {
    const text = form.value.email ? form.value.email.toLowerCase() : "";
    analyticsComposable.trackFormEvent(
      "onclick",
      "click",
      "blur",
      "main content",
      "request info ^ email",
      text
    );
  };

  const handleEmailInput = () => {
    isEmailValid.value = null;
    formDisabled.value = false;
    emailErrorMessage.value = "";
    validateEmail();
  };

  const handlePhoneBlur = () => {
    analyticsComposable.trackFormEvent(
      "onclick",
      "click",
      "blur",
      "main content",
      "request info ^ phone number",
      form.value.phone.formattedNumber ? form.value.phone.formattedNumber : ""
    );
  };

  const handlePhoneUpdate = (payload: Result) => {
    isPhoneValid.value = null;
    formDisabled.value = false;

    form.value.phone.formattedNumber = payload.formatInternational!;
    form.value.phone.nationalNumber = payload.nationalNumber!;
    form.value.phone.countryCode = payload.countryCode!;
    form.value.phone.isValid = payload.isValid;
  };

  const handleMilitaryClick = () => {
    analyticsComposable.trackSelectEvent(
      "onclick",
      "click",
      "checkbox",
      "main content",
      "request info ^ military or military dependent",
      form.value.isMilitary == false ? "yes" : "no"
    );
  };

  const handleSubmit = () => {
    showLoader.value = true;
    if (currentProgramName.value && currentProgramCode.value) {
      const career =
        form.value.degreeType && form.value.degreeType.value
          ? form.value.degreeType.value
          : RfiService.getCareerValueByProgramCode(
              currentProgramCode.value,
              allPrograms.value
            );
      const formTrackingData = RfiService.getFormTrackingData(
        "request info",
        "submit",
        form.value as RfiFormFieldShape,
        career!,
        currentProgramName.value,
        currentProgramCode.value
      );
      analyticsComposable.trackEvent(formTrackingData);
      // verify valid data submitted through briteverify
      verifyBVData();
    } else {
      showLoader.value = false;
      console.error("program name or code missing");
    }
  };

  const verifyBVData = async () => {
    try {
      showLoader.value = true;

      // get briteverify response
      const axiosBriteverifyResponse = await RfiService.getBriteverifyResponse(
        form.value.phone!.formattedNumber!,
        form.value.email!,
        isUsOrCanada.value
      );

      // check for valid phone, if US or CA
      form.value.phone.isValid = RfiService.isVerifiedPhone(
        axiosBriteverifyResponse,
        isUsOrCanada.value
      );
      isPhoneValid.value = form.value.phone.isValid;

      // check for valid email
      isEmailValid.value = RfiService.isVerifiedEmail(axiosBriteverifyResponse);

      // track event
      analyticsComposable.trackEvent({
        event: "briteverify_contact",
        phone: form.value.phone.formattedNumber
          ? form.value.phone.formattedNumber
          : undefined,
        email: form.value.email,
        // @ts-ignore
        email_valid: isEmailValid.value,
        // @ts-ignore
        phone_valid: form.value.phone.isValid,
      });

      // submit lead
      if (form.value.phone.isValid == true && isEmailValid.value == true) {
        handleLeadSubmission();
      }

      // return false if phone or email is invalid
      showLoader.value = false;
      formDisabled.value = true;
      return false;
    } catch (error) {
      console.error(error);

      // send BV error to gtm
      // @ts-ignore Fix this trackEvent has some required props that this doesn't satisfy, need to see if required or not
      analyticsComposable.trackEvent({
        event: "briteverify_contact",
        phone: form.value.phone.formattedNumber
          ? form.value.phone.formattedNumber
          : undefined,
        email: form.value.email,
        //@ts-ignore
        email_valid: "error",
        //@ts-ignore
        phone_valid: "error",
      });

      showLoader.value = false;

      return false;
    }
  };

  const handleLeadSubmission = async () => {
    if (currentProgramName.value && currentProgramCode.value) {
      const career =
        form.value.degreeType && form.value.degreeType.value
          ? form.value.degreeType.value
          : RfiService.getCareerValueByProgramCode(
              currentProgramCode.value,
              allPrograms.value
            );

      const formTrackingData = RfiService.getMilitaryFormTrackingData(
        "request info",
        "submit",
        form.value as RfiFormFieldShape,
        career!,
        currentProgramName.value,
        currentProgramCode.value,
        form.value.isMilitary
      );

      let leadData: ReturnType<typeof RfiService.getMilitaryLeadData>;
      const validatedFormData = rfiZodSchema.safeParse(form.value);

      if (!validatedFormData.success) {
        // Might want to set up more strict checks
        console.error(
          "form validation issue, falling back to default form data",
          validatedFormData.error.errors[0].message
        );
        leadData = RfiService.getMilitaryLeadData(
          form.value as RfiFormFieldShape,
          currentProgramCode.value
        );
      } else {
        leadData = RfiService.getMilitaryLeadData(
          validatedFormData.data as RfiFormFieldShape,
          currentProgramCode.value
        );
      }
      try {
        formDisabled.value = true;

        const axiosResponse = await RfiService.addRFILead(leadData);

        // @ts-ignore
        analyticsComposable.trackEvent({
          ...formTrackingData,
          event: "form",
          name: "onsubmit",
          action: "rfi submit",
          type: "submit",
          text: "rfi form submitted",
        });

        setTimeout(async () => {
          showLoader.value = false;
          const category = options.career;
          // Check for Undergraduate
          if (
            form.value.degreeType?.value === "Undergraduate" ||
            category === "Undergraduate"
          ) {
            await navigateTo("/new/degree-program-request-info-thank-you/a", {
              open: {
                target: "_self",
              },
            });
          }
          // Check for Graduate
          else if (
            form.value.degreeType?.value === "Graduate" ||
            category === "Graduate"
          ) {
            await navigateTo("/new/degree-program-request-info-thank-you/b", {
              open: {
                target: "_self",
              },
            });
          }
          // Default navigation if neither Undergraduate nor Graduate
          else {
            await navigateTo("/degree-program-request-info-thank-you", {
              open: {
                target: "_self",
              },
            });
          }
        }, 2000);
      } catch (error) {
        //@ts-ignore
        analyticsComposable.trackEvent({
          ...formTrackingData,
          text: "rfi form submission error",
        });
        console.error(error);
      }
    } else {
      // Handle the case when the program name or code is missing
      console.error("Program name or code missing");
    }
  };

  const loadInitialOptions = () => {
    if (options.programName && options.programId) {
      form.value.program = {
        value: options.programId,
        text: options.programName,
      };
    } else if (options.programName || options.programId) {
      console.error(
        "missing either program name or program code when setting explicitly"
      );
    }

    // don't need categories & interest areas for degree page rfi
    allCategories.value = RfiService.getAllFormattedCategories(
      data?.value?.allCategory!
    );
    allInterestAreas.value = RfiService.getAllFormattedInterestAreas(
      data?.value?.allInterestAreaType!
    );
    if (optionsWithDefaults.isStudyPage) {
      if (optionsWithDefaults.programList) {
        allPrograms.value =
          InterestAreaRfiService.generateFormattedProgramCollection(
            optionsWithDefaults.programList
          );
      } else {
        console.error("Program list is required when isStudyPage is true");
      }

      categoryProgramsMap.value =
        InterestAreaRfiService.generateCategoryProgramsMap(
          allCategories.value!,
          allPrograms.value
        );
    } else {
      allPrograms.value = RfiService.getAllFormattedPrograms(
        data?.value?.allDegreeSeekingProgram!
      );
      // relationalProgramData
      relationalProgramData.value = RfiService.getRelationalProgramData(
        allCategories.value!,
        allPrograms!.value!
      );
    }

    // initial options
    degreeTypeOptions.value = RfiService.getDegreeTypeOptions(
      allCategories.value!
    );
    interestAreaOptions.value = RfiService.getInterestAreaOptions(
      allInterestAreas.value!
    );
    programOptions.value = RfiService.getProgramOptions(allPrograms.value);
  };

  watch(
    data,
    (newData, oldData) => {
      if (
        newData?.allCategory &&
        newData?.allDegreeSeekingProgram &&
        newData?.allInterestAreaType
      ) {
        loadInitialOptions();
      }
    },
    { once: true }
  );

  return {
    form,
    isEmailValid,
    isPhoneValid,
    emailErrorMessage,
    firstNameErrorMessage,
    lastNameErrorMessage,
    phoneErrorMessage,
    programIsValid,
    formDataIsValid,
    submitDisabled,
    showLoader,
    formDisabled,
    handleDegreeTypeChange,
    allCategories,
    allInterestAreas,
    allPrograms,
    programOptions,
    interestAreaOptions,
    degreeTypeOptions,
    relationalProgramData,
    isUsOrCanada,
    currentProgramCode,
    currentProgramName,
    handleInterestAreaChange,
    handleProgramChange,
    handleFirstNameBlur,
    handleLastNameBlur,
    handleEmailBlur,
    handleEmailInput,
    validateEmail,
    handlePhoneBlur,
    handlePhoneUpdate,
    handleMilitaryClick,
    handleSubmit,
    verifyBVData,
    handleLeadSubmission,
  };
}
